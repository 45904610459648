import * as React from "react";

const VisiteVirtuelleUnleashedSpray = () => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!4v1701851374773!6m8!1m7!1sCAoSLEFGM VFpcE5vNGs1OHkyV2RjMk1jV0FCTnpVYm9DU25hdXlRaHpXNEItVE0x!2m2!1d48.8698531!2d2.31 90501!3f304.6222676151689!4f1.1411292400064923!5f0.7820865974627469"
      width="600" 
      height="450" 
        style={{ width: "100vw", height: "calc(100vh - 50px)" }}
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"
      title="Visite virtuelle de GHOST GALERIE"
      frameborder="0"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      allowvr="true"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    ></iframe>
    // <iframe
    //   title="Visite virtuelle de GHOST GALERIE"
   
    //   src="https://www.klapty.com/tour/tunnel/YQV0v9USAH"
    //   frameborder="0"
    //   allowfullscreen="true"
    //   mozallowfullscreen="true"
    //   webkitallowfullscreen="true"
    //   allowvr="true"
    //   allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; vr"
    // ></iframe>
  );
};

export default VisiteVirtuelleUnleashedSpray;
